@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

#app{
    font-family: 'Nunito';
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.section{
    padding: 0px 60px;
    min-height: 500px;
    background-color: #2D89B2;
}


.container-nav{
    margin: 0px auto;

}

.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container{
    max-width: 1160px;
    margin: 0px auto;
}

.navigation{
    padding: 15px 60px;
    background-color: white;
}

.navigation .nav-links{
    display: flex;
    align-items: center;
    font-size: 16px;
}

.navigation .nav-links p{
    margin-left: 64px;
    cursor: pointer;
    padding: 0px 5px;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s ease;
    font-size: 17px;
    font-weight: 500;

}

.navigation .nav-links p:hover{
    cursor: pointer;
    border-bottom: 2px solid #2D89B2;
}


.logo{
    display: flex;
    align-items: center;
}

.logo img{
    width: 50px;
    height: 50px;
}

.logo h2{
    font-size: 20px;
    padding-left: 10px;
}

.flexbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}


.text-hero h1{
    max-width: 517px;
    color: white;
    font-size: 28px;
    font-weight: bold;
}

.text-hero p{
    max-width: 511px;
    color: white;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 2px;
    opacity: 85%;
}

.hero-img{
    max-width: 500px;
    padding-top: 50px;
}

.hero-img img{
    width: 700px;
}

.content-section{
    background-color: white;

}

.first-content{
    display: flex;
    align-items: center;
    margin-top: 80px;
}

.first-content .first-text {
    max-width: 800px;
}

.first-content .first-text p{
    color: black;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 2px;
    opacity: 55%;
}

.first-img{
    margin-left: 100px;
}

.first-img img{
    width: 500px;
    border-radius: 8px;
    box-shadow: 4px 4px 3px 2px rgba(61, 61, 61, 0.438);
}

.hr-line{
    border-bottom: 1px;
    border-color: rgba(0, 0, 0, 0.308);
    max-width: 900px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.second-content{
    display: flex;
    align-items: center;
    margin-top: 80px;
}


.second-img img{
    width: 300px;
    border-radius: 6px;
    box-shadow: 4px 4px 3px 2px rgba(61, 61, 61, 0.438);
}

.second-content .second-text{
    max-width: 850px;
    margin-left: 50px;
}

.second-content .second-text p{
    color: black;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 2px;
    opacity: 55%;
}


.third-content{
    display: flex;
    align-items: center;
    margin-top: 80px;
}

.third-content .third-text{
    max-width: 800px;
}

.third-content .third-text p{
    color: black;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 2px;
    opacity: 55%;
}

.third-img{
    margin-left: 100px;
    margin-bottom: 80px;
}

.third-img img{
    width: 400px;
    border-radius: 6px;
    box-shadow: 4px 4px 3px 2px rgba(61, 61, 61, 0.438);
}

.section-explain{
    background-color: #EBEBEB;
}


.Explain-div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-text{
    margin-top: 130px;
    max-width: 600px;
    border-radius: 10px;
    background-color: rgba(217,217,217,0.50);
    box-shadow: 4px 4px 3px 2px rgba(61, 61, 61, 0.438);
    padding: 60px 80px;
}

.card-text h2{
    font-size: 30px;
}

.card-text p{
    color: black;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 2px;
    opacity: 75%;
}

.explain-img{
    margin-top: 180px;
    margin-bottom: 150px;
}

.explain-img img{
    width: 1100px;
    border-radius: 8px;
    box-shadow: 4px 4px 3px 2px rgba(61, 61, 61, 0.438);
}

.contact-end-section{
    padding: 60px 60px;
    background-color: #2D89B2;
}

.contact{
    display: flex;
    align-items: center;
    
}


.vertical-line{
    height: 150px;
    border-color: rgb(255, 255, 255,0.7);
}

.contact-email{
    max-width: 400px;
    color: white;
    font-size: 16px;
}

.enter-contact{
    max-width: 600px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.enter-contact p{
    font-size: 20px;
    color: white;
}

.enter-contact a{
    margin-top: 20px;
    text-decoration: none;
    text-align: center;
    color: white;
    background-color: rgb(255, 255, 255,0.2);
    padding: 10px 50px;
    border-radius: 12px;
}

.enter-contact a:hover{
    background-color: rgb(255, 255, 255,0.3);;
}

.footer{
    padding: 10px 60px;
    display: flex;
    justify-content: center;
}

.wpp-icon img{
    width: 70px;
    position: fixed;
    z-index: 1000;
    bottom: 20px;    
    right: 20px;    
    z-index: 1000;   
    cursor: pointer;
}